import React from "react";

const Navbar = () => {
  return (
    <div className="w3-top">
      <div id="navBar" className="w3-bar">
        <a href="#home" className="w3-bar-item w3-button w3-text-white">
          <img src="./images/acfa_logo.png" alt="ACFA LOGO" id="logoacfa" />
        </a>
        <a
          href="mailto:contact@acfa.io?subject=COWORKING ACFA"
          rel="noreferrer"
          className="w3-bar-item w3-button w3-padding-large w3-text-white w3-right w3-large w3-hide-small"
        >
          CONTACT
        </a>
        <a
          href="#faqs"
          className="w3-bar-item w3-button w3-padding-large w3-text-white w3-right w3-large w3-hide-small"
        >
          FAQs
        </a>
        <a
          href="#leavandeveled"
          className="w3-bar-item w3-button w3-padding-large w3-text-white w3-right w3-large"
        >
          ARTISTES
        </a>
        <a
          href="#howitworks"
          className="w3-bar-item w3-button w3-padding-large w3-text-white w3-right w3-large"
        >
          CONCEPT
        </a>
      </div>
    </div>
  );
};

export default Navbar;
