import React from "react";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  const theYear = new Date().toLocaleDateString("fr-FR", { year: "numeric" });

  return (
    <div id="footer" className="w3-padding-64">
      <div className="w3-content">
        <div className="w3-col s12 m4 l4">
          <div className="w3-col s12 m8 w3-center w3-padding">
            <img
              src="./images/ACFA-WM-WHITE.png"
              alt="Logo ACFA blanc"
              id="logofooter"
            />
            <span className="coworking">COWORKING</span>
          </div>
        </div>
        <div id="footer3" class="w3-col s12 m4 l4 w3-padding">
          <div className="w3-col s11 m9">
            <p class="w3-text-white">
              La certification des oeuvres d'art grâce à la technologie des
              NFTs. Une certification unique et infalsifiable sur la blockchain
              Solana.
            </p>
            <p class="w3-text-white">Copyright © {theYear} - ACFA.io</p>
            <p class="w3-text-white">
              Nous suivre :
              <a
                class="w3-text-white"
                href="https://www.fb.me/acfa.io"
                target="_blank"
                rel="noreferrer"
              >
                <span className="span-socialnetwork">
                  <FaFacebookSquare />
                </span>
              </a>
              <a
                class="w3-text-white"
                href="https://www.instagram.com/acfa.io/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="span-socialnetwork">
                  <BsInstagram />
                </span>
              </a>
              <a
                class="w3-text-white"
                href="https://linkedin.com/company/acfa-io"
                target="_blank"
                rel="noreferrer"
              >
                <span className="span-socialnetwork">
                  <BsLinkedin />
                </span>
              </a>
            </p>
          </div>
        </div>
        <div id="footer2" class="w3-col s12 m4 l4 w3-padding">
          <p class="w3-text-white">
            <a
              class="w3-text-white a-link"
              target="_blank"
              rel="noreferrer"
              href="https://docs.acfa.io/nosservices"
            >
              Comment ACFA fonctionne?
            </a>
          </p>
          <p class="w3-text-white">
            <a
              class="w3-text-white a-link"
              target="_blank"
              rel="noreferrer"
              href="https://docs.acfa.io/faqscollectionneurs"
            >
              FAQs
            </a>
          </p>
          <p class="w3-text-white">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.acfa.io/ml"
              class="w3-text-white a-link"
            >
              Mentions Légales
            </a>
          </p>
          <p class="w3-text-white">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.acfa.io/cgu"
              class="w3-text-white a-link"
            >
              Conditions Générales d'utilisation
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
