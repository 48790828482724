import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FaUserCheck, FaPaintbrush } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";

const Home = () => {
  return (
    <div>
      <Navbar />
      <div id="home">
        <div id="titlelogohome" className="">
          <h1 id="titlehome" className="w3-text-white w3-xxlarge w3-justify">
            ACFA Coworking : L'Atelier des Artistes <br></br>
            Imaginez un espace où l'art respire, où chaque coin est une toile en
            devenir.
          </h1>
          <hr />
          <p className="w3-text-white w3-large">
            Voici ACFA Coworking, un sanctuaire pour les artistes, où le loyer
            se paie en couleurs, en émotions, en œuvres d'art. Sans paperasse,
            sans engagement.
          </p>
          <a
            href="mailto:contact@acfa.io?subject=COWORKING ACFA"
            rel="noreferrer"
            className="w3-button w3-large w3-contact-button"
          >
            Nous contacter !
          </a>
        </div>
        <img
          src="./images/leavandeveld.png"
          alt="Léa Vandeveld - Artiste"
          id="leavandeveldhomeimage"
        />
      </div>
      <div id="howitworks" className="w3-content w3-padding-64 w3-row">
        <h2
          id="title-howitworks"
          className="w3-xxxlarge w3-padding  w3-col s12"
        >
          Le Principe :
        </h2>
        <div className="w3-row">
          <div className="w3-col m4 s12 w3-padding">
            <div className="w3-card w3-padding card-howitworks">
              <p>
                <span className="span-howitworks">
                  <FaUserCheck />
                </span>
              </p>
              <h4 className="h4-bold">1. Sélection des Artistes :</h4>
              <p>
                Chaque artiste inscrit sur ACFA.io est invité à postuler pour
                une place au sein de notre Coworking. Nous recherchons les
                artistes en quête d'un tremplin pour dénicher leur atelier de
                demain.
              </p>
            </div>
          </div>
          <div className="w3-col m4 s12 w3-padding">
            <div className="w3-card w3-padding card-howitworks">
              <p>
                <span className="span-howitworks">
                  <FaPaintbrush />
                </span>
              </p>
              <h4 className="h4-bold">2. Libère ta Créativité :</h4>
              <p>
                Ici, chaque recoin est pensé pour que ton art prenne son envol.
                Le coworking t'offre, en tant qu'artiste, ton propre espace de
                création, ton atelier, ton sanctuaire artistique. Échappe aux
                contraintes d'un bail, d'une garantie ou de justificatifs
                financiers. Tout a été simplifié pour créer de bonnes bases.
              </p>
            </div>
          </div>
          <div className="w3-col m4 s12 w3-padding">
            <div className="w3-card w3-padding card-howitworks">
              <p>
                <span className="span-howitworks">
                  <GiReceiveMoney />
                </span>
              </p>
              <h4 className="h4-bold">3. On gère le reste :</h4>
              <p>
                ACFA Coworking prend en charge l'intégralité des frais du local.
                En guise de loyer, les artistes offrent en retour des œuvres
                d'art. C'est ACFA qui se chargera de les mettre en lumière et
                d'en assurer la vente, couvrant ainsi les dépenses du local.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="thankfull" className="w3-padding-64 w3-row w3-light-gray">
        <div className="w3-col m2 w3-hide-small">
          <img
            src="./images/acfa_stamp.png"
            alt="LOGO ACFA YELLOW"
            className="w3-image w3-padding"
          />
        </div>
        <div className="w3-col m9">
          <h1 id="text-thankfull" className="w3-xxxlarge">
            "Grâce à ACFA Coworking, j'ai mon sanctuaire. Un lieu où chaque
            pinceau, chaque trait compte. Et mes œuvres ? Elles paient le
            loyer."
          </h1>
        </div>
      </div>
      <div className="w3-padding">
        <div id="leavandeveled" className="w3-content w3-row w3-card">
          <div className="w3-col s12 m4 w3-row-padding">
            <img
              src="./images/leavandeveldbleue.jpg"
              alt="Léa Vandeveld - Artiste - Bleue"
              className="w3-image"
            />
          </div>
          <div className="w3-col s12 m8 w3-row-padding">
            <h2 id="artiste-lea" className="w3-xxxlarge">
              Notre première artiste à rejoindre le Coworking : Léa Vandeveld
            </h2>
            <p className="w3-justify w3-large">
              Je suis Léa Vandeveld, Artiste peintre de 25 ans d'origine belge,
              hors codes mais pas hors cadre... J'ai rapidement posé mon regard
              admiratif sur les oeuvres de Picasso et le cubisme en général.
              Depuis mon plus jeune âge, c'est à Bali que je découvre mon
              univers artistique. Une transmission, une révélation. Je développe
              alors le Make-up for Art, un concept innovant et écoresponsable de
              peindre avec du maquillage recyclé sur toile, consacré lors de la
              soirée Vogue à Paris pour la marque Paul&Joe. Une première
              reconnaissance à 17 ans mais surtout une formidable stimulation
              pour partir sur de nouveaux horizons. Ma démarche artistique et
              mon cheminement personnel se confondent. "L'art doit avoir du sens
              et je veux rendre à la vie ce qu'elle m'apporte" Le Make-up for
              Art est un art conceptuel et innovant. Il permet de surprendre le
              consommateur, d'ouvrir les consciences au recyclage et
              d'interpeler le monde de l'art. Show must go on... Et ce n'est que
              le début !
            </p>
            <a
              href="https://acfa.io/artiste/vandeveld"
              target="_blank"
              rel="noreferrer"
              className="w3-button w3-large w3-acfa-button"
            >
              Voir ma page sur ACFA.IO !
            </a>
          </div>
        </div>
      </div>
      <div id="faqs" className="w3-padding-64 w3-row w3-light-gray">
        <div className="w3-content">
          <h2
            id="title-faqs"
            className="w3-xxxlarge w3-padding w3-col s12 title-bold"
          >
            Comment le Coworking ACFA fonctionne?
          </h2>
          <div className="w3-row w3-padding">
            <div className="w3-col s12 m6">
              <div className="w3-col m11 s12">
                <h3 className="w3-xxlarge title-bold">Pour les artistes</h3>
                <p className="w3-large p-bold">Sélection artistique :</p>
                <p className="p-margin">
                  Envoyez-nous un email décrivant votre passion et la raison
                  pour laquelle vous cherchez un espace pour donner vie à votre
                  art. Après avoir étudié votre profil sur ACFA, nous vous
                  invitons à un entretien pour échanger sur les modalités. Si
                  tout s'aligne, bienvenue dans votre nouvel atelier !
                </p>
                <p className="w3-large p-bold">Inscription ACFA :</p>
                <p className="p-margin">
                  Tout artiste désireux de rejoindre le Coworking doit être
                  inscrit sur notre plateforme ACFA.io. Sans cette inscription,
                  votre demande de coworking ne sera pas prise en compte.
                </p>
                <p className="w3-large p-bold">Loyer Créatif :</p>
                <p className="p-margin">
                  Ici, votre talent est votre monnaie. En guise de loyer, nous
                  vous invitons à nous offrir l'une de vos œuvres de temps à
                  autre. Nous pourrions par la suite la mettre en vente pour
                  couvrir nos frais.
                </p>
                <p className="w3-large p-bold">
                  Votre Toile, Votre Responsabilité :
                </p>
                <p className="p-margin">
                  Nous prenons en charge les frais essentiels tels que
                  l'électricité, l'eau, etc. Toutefois, le matériel artistique
                  reste à votre charge.
                </p>
                <p className="w3-large p-bold">Durée d'engagement :</p>
                <p className="p-margin">
                  Que votre inspiration soit éphémère ou que vous ayez un projet
                  à long terme, la durée d'engagement est flexible, allant de
                  quelques jours à plusieurs années. Notre espace est votre
                  toile vierge.
                </p>
              </div>
            </div>
            <div className="w3-col s12 m6">
              <div className="w3-col m11 s12">
                <h3 className="w3-xxlarge title-bold">
                  Pour les propriétaires
                </h3>
                <p className="w3-large p-bold">Disponibilité d'un Espace ?</p>
                <p className="p-margin">
                  Si vous possédez un local inoccupé, avez-vous envisagé
                  d'accueillir des artistes ? Nous avons récemment inauguré
                  notre premier espace de coworking artistique et sommes en
                  phase d'expansion. Nous serions ravis d'explorer cette
                  opportunité avec vous.
                </p>
                <p className="w3-large p-bold">Critères du Local :</p>
                <p className="p-margin">
                  Il est essentiel que le local soit prêt à l'emploi sans
                  nécessité de travaux majeurs. Nous établirons un contrat
                  couvrant les aspects essentiels tels que l'électricité, l'eau
                  et la sécurité. De plus, nous sommes ouverts à diverses
                  modalités, y compris des partenariats avec des associations
                  pour des arrangements spécifiques concernant le loyer.
                </p>
                <p className="w3-large p-bold">Modalités de Loyer :</p>
                <p className="p-margin">
                  Concernant le loyer, nous proposons une approche innovante :
                  vous pourriez recevoir des œuvres d'art en guise de paiement,
                  ou nous pourrions vendre ces œuvres pour couvrir le loyer.
                  Notre but est de valoriser l'espace tout en offrant une
                  flexibilité financière.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
